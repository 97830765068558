var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var environments = {
    Local: {
        debugTranslations: true,
        environment: 'Local',
        isLocal: true,
        isProduction: false,
    },
    Dev: {
        debugTranslations: false,
        environment: 'Dev',
        isLocal: false,
        isProduction: true,
    },
    Staging: {
        debugTranslations: false,
        environment: 'Staging',
        isLocal: false,
        isProduction: true,
    },
    Prod: {
        debugTranslations: false,
        environment: 'Prod',
        isLocal: false,
        isProduction: true,
    },
};
var environmentCurrent = environments[process.env.NEXT_PUBLIC_ENVIRONMENT || 'Local'];
var AppConfig = /** @class */ (function () {
    function AppConfig(env) {
        if (env) {
            this.env = env;
        }
        else {
            this.env = {};
        }
        this.extraConfig = {};
    }
    AppConfig.prototype.fetchSetting = function (settingKey, isRequired) {
        if (isRequired === void 0) { isRequired = false; }
        var setting = this.env[settingKey];
        if (isRequired && !setting) {
            throw new Error("Missing ".concat(settingKey, " in .env.local"));
        }
        return setting;
    };
    AppConfig.prototype.addSetting = function (settingKey, envVar, isRequired) {
        this.extraConfig[settingKey] = this.fetchSetting(envVar, isRequired);
    };
    AppConfig.prototype.addFixedSetting = function (settingKey, value) {
        this.extraConfig[settingKey] = value;
    };
    AppConfig.prototype.getSetting = function (settingKey) {
        return this.appConfig[settingKey];
    };
    AppConfig.prototype.mergeSettings = function (other) {
        this.env = __assign(__assign({}, this.env), other.env);
        return this;
    };
    Object.defineProperty(AppConfig.prototype, "appConfig", {
        get: function () {
            return __assign(__assign({ appName: this.fetchSetting('NEXT_PUBLIC_NAME') || 'BetaBlocks', apiHostname: this.fetchSetting('NEXT_PUBLIC_API_HOSTNAME') || '', apiBaseUrl: this.fetchSetting('NEXT_PUBLIC_API_URL', true), apiReplacementPort: this.fetchSetting('NEXT_PUBLIC_API_REPLACEMENT_PORT') || 8000, apiDefaultLimit: 100, apiTimeout: 60000, apiTokenDuration: 2 * 60 * 1000, apiRefreshTokenKey: '__REFRESH_TOKEN_KEY__', authUserKey: '__AUTH_USER__', apiTokenKey: '__TOKEN_KEY__', apiTokenUpdatedKey: '__TOKEN_UPDATED_KEY__', sentryDsn: this.fetchSetting('NEXT_PUBLIC_SENTRY_DSN') || '', betablocksEmail: this.fetchSetting('NEXT_PUBLIC_BETABLOCKS_EMAIL') || 'support@betablocks.co', betablocksSubscriptionEmail: this.fetchSetting('NEXT_PUBLIC_BETABLOCKS_SUBSCRIPTION_EMAIL') ||
                    'subscriptions@betablocks.com', stripePublishableKey: this.fetchSetting('NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY'), stripeApiVersion: this.fetchSetting('NEXT_PUBLIC_STRIPE_API_VERSION') || '2020-08-27', platformAddress: this.fetchSetting('NEXT_PUBLIC_PLATFORM_ADDRESS') || '', partnerAddress: this.fetchSetting('NEXT_PUBLIC_PARTNER_ADDRESS') || '', freeStorefrontLimit: this.fetchSetting('NEXT_PUBLIC_FREE_STOREFRONT_LIMIT') || 1, freeUserLicenseLimit: this.fetchSetting('NEXT_PUBLIC_FREE_USER_LICENSES_LIMIT') || 1, freeMintedTokenLimit: this.fetchSetting('NEXT_PUBLIC_FREE_MINTED_TOKEN_LIMIT') || 5 }, this.extraConfig), environmentCurrent);
        },
        enumerable: false,
        configurable: true
    });
    return AppConfig;
}());
export { AppConfig };
var baseConfig = new AppConfig();
// Needed so that Babel transform works
export var FAKE_ENV = {
    NEXT_PUBLIC_API_HOSTNAME: process.env.NEXT_PUBLIC_API_HOSTNAME,
    NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
    NEXT_PUBLIC_API_REPLACEMENT_PORT: process.env.NEXT_PUBLIC_API_REPLACEMENT_PORT,
    NEXT_PUBLIC_NAME: process.env.NEXT_PUBLIC_NAME,
    NEXT_PUBLIC_ROOT_DOMAIN_URL: process.env.NEXT_PUBLIC_ROOT_DOMAIN_URL,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_ETHERSCAN_URL: process.env.NEXT_PUBLIC_ETHERSCAN_URL,
    NEXT_PUBLIC_BETABLOCKS_EMAIL: process.env.NEXT_PUBLIC_BETABLOCKS_EMAIL,
    NEXT_PUBLIC_BETABLOCKS_SUBSCRIPTION_EMAIL: process.env.NEXT_PUBLIC_BETABLOCKS_SUBSCRIPTION_EMAIL,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    NEXT_PUBLIC_STRIPE_API_VERSION: process.env.NEXT_PUBLIC_STRIPE_API_VERSION,
    NEXT_PUBLIC_PLATFORM_ADDRESS: process.env.NEXT_PUBLIC_PARTNER_ADDRESS,
    NEXT_PUBLIC_PARTNER_ADDRESS: process.env.NEXT_PUBLIC_PARTNER_ADDRESS,
    NEXT_PUBLIC_FREE_STOREFRONT_LIMIT: process.env.NEXT_PUBLIC_FREE_STOREFRONT_LIMIT,
    NEXT_PUBLIC_FREE_USER_LICENSES_LIMIT: process.env.NEXT_PUBLIC_FREE_USER_LICENSES_LIMIT,
    NEXT_PUBLIC_FREE_MINTED_TOKEN_LIMIT: process.env.NEXT_PUBLIC_FREE_MINTED_TOKEN_LIMIT,
};
function initConfig() {
    var appConfig = new AppConfig(FAKE_ENV);
    baseConfig.mergeSettings(appConfig);
    baseConfig.addFixedSetting('apiDefaultLimit', -1);
    baseConfig.addFixedSetting('apiTimeout', 60000);
    baseConfig.addFixedSetting('apiTokenDuration', 2 * 60 * 1000);
    return baseConfig.appConfig;
}
export default initConfig();
