import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { GetServerSidePropsContext } from 'next'

const TOKEN_KEY = 'token'

export function getTokenCookie(context?: GetServerSidePropsContext): string {
  return getCookie(TOKEN_KEY, context) as string
}

export function setTokenCookie(token: string, context?: GetServerSidePropsContext): void {
  setCookie(TOKEN_KEY, token, context)
}

export function deleteTokenCookie(context?: GetServerSidePropsContext): void {
  deleteCookie(TOKEN_KEY, context)
}
