var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ky from 'ky';
import appConfig from '../config/appConfig';
import { AbundanceAPI } from './abundance';
import { APIKeysAPI } from './apiKeys';
import { AuctionsAPI } from './auctions';
import { AuthAPI } from './auth';
import { handleError } from './base';
import { BidsAPI } from './bids';
import { BMWAPI } from './bmw';
import { CollectionsAPI } from './collections';
import { APIConfig } from './config';
import { EditionsAPI } from './editions';
import { FAQsAPI } from './faqs';
import { LogsAPI } from './logs';
import { MFAAPI } from './mfa';
import { MMAAPI } from './mma';
import { NFTDistributionItemAPI } from './nftDistributionItem';
import { NFTDistributionListAPI } from './nftDistributionList';
import { NFTsAPI } from './nfts';
import { PagesAPI } from './pages';
import { PaperAPI } from './paper';
import { PayForGasAPI } from './payForGas';
import { PelxpAPI } from './pelxp';
import { PIXApi } from './pix';
import { StaticPagesAPI } from './staticpages';
import { StatsAPI } from './stats';
import { StorefrontBlockchainAPI } from './storefrontBlockchain';
import { StorefrontsAPI } from './storefronts';
import { StripeAPI } from './stripe';
import { SubaccountsAPI } from './subaccounts';
import { TagsAPI } from './tags';
import { UsersAPI } from './users';
import { WalletsAPI } from './wallets';
import { WebhooksAPI } from './webhooks';
var API = /** @class */ (function () {
    function API(options) {
        var _this = this;
        var _a;
        this.config = new APIConfig();
        var opts = options !== null && options !== void 0 ? options : {};
        var isServer = typeof window === 'undefined';
        if (opts === null || opts === void 0 ? void 0 : opts.req) {
            var domain = (_a = opts.req.headers.host) === null || _a === void 0 ? void 0 : _a.replace(/:\d+$/, ":".concat(appConfig.apiReplacementPort));
            if (isServer && domain) {
                this.config.extraHeaders = {
                    'X-Storefront-Domain': domain,
                };
            }
            var host = isServer ? appConfig.apiHostname : domain;
            var proto = appConfig.isLocal || isServer ? 'http' : 'https';
            var baseUrl = appConfig.apiBaseUrl;
            this.config.baseUrl = "".concat(proto, "://").concat(host).concat(baseUrl);
        }
        if (opts === null || opts === void 0 ? void 0 : opts.token) {
            this.config.token = opts.token;
        }
        this._ky = ky.extend({
            hooks: {
                beforeRequest: [
                    function (request) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (appConfig.isLocal) {
                                console.debug('[Debug][ky]', this.config.baseUrl, request.method, request.url, request);
                            }
                            return [2 /*return*/];
                        });
                    }); },
                ],
            },
            retry: {
                limit: 5,
                methods: ['get', 'put', 'post', 'head', 'patch', 'delete', 'options', 'trace'],
                statusCodes: [408, 413, 429, 502, 503, 504],
            },
        });
        this.abundance = new AbundanceAPI(this.config, this._ky);
        this.auctions = new AuctionsAPI(this.config, this._ky);
        this.auth = new AuthAPI(this.config, this._ky);
        this.bids = new BidsAPI(this.config, this._ky);
        this.collections = new CollectionsAPI(this.config, this._ky);
        this.editions = new EditionsAPI(this.config, this._ky);
        this.faqs = new FAQsAPI(this.config, this._ky);
        this.logs = new LogsAPI(this.config, this._ky);
        this.mma = new MMAAPI(this.config, this._ky);
        this.pfg = new PayForGasAPI(this.config, this._ky);
        this.bmw = new BMWAPI(this.config, this._ky);
        this.pix = new PIXApi(this.config, this._ky);
        this.mfa = new MFAAPI(this.config, this._ky);
        this.pelxp = new PelxpAPI(this.config, this._ky);
        this.nfts = new NFTsAPI(this.config, this._ky);
        this.pages = new PagesAPI(this.config, this._ky);
        this.paper = new PaperAPI(this.config, this._ky);
        this.staticpages = new StaticPagesAPI(this.config, this._ky);
        this.stats = new StatsAPI(this.config, this._ky);
        this.storefronts = new StorefrontsAPI(this.config, this._ky);
        this.storefrontBlockchains = new StorefrontBlockchainAPI(this.config, this._ky);
        this.stripe = new StripeAPI(this.config, this._ky);
        this.subaccounts = new SubaccountsAPI(this.config, this._ky);
        this.tags = new TagsAPI(this.config, this._ky);
        this.users = new UsersAPI(this.config, this._ky);
        this.apiKeys = new APIKeysAPI(this.config, this._ky);
        this.webhooks = new WebhooksAPI(this.config, this._ky);
        this.wallets = new WalletsAPI(this.config, this._ky);
        this.nftDistributionList = new NFTDistributionListAPI(this.config, this._ky);
        this.nftDistributionItem = new NFTDistributionItemAPI(this.config, this._ky);
    }
    API.prototype.handleError = function (error) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (appConfig.isLocal) {
                    console.error(error);
                }
                return [2 /*return*/, handleError(error)];
            });
        });
    };
    return API;
}());
export default API;
