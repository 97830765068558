import React from 'react'

const Apple: React.FC = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4513 4.59583C12.7663 4.23273 13.0065 3.81098 13.1581 3.35478C13.3097 2.89859 13.3697 2.41694 13.3346 1.9375C12.3674 2.01557 11.4696 2.47046 10.8346 3.20417C10.5305 3.55581 10.3001 3.9649 10.1569 4.4072C10.0138 4.84951 9.96087 5.31604 10.0013 5.77917C10.4731 5.7831 10.9394 5.67841 11.3643 5.47322C11.7891 5.26802 12.161 4.96781 12.4513 4.59583ZM14.5513 10.7958C14.5569 10.1573 14.7251 9.53068 15.04 8.97517C15.3549 8.41966 15.8062 7.95355 16.3513 7.62083C16.0073 7.1252 15.5526 6.71653 15.0231 6.42723C14.4937 6.13793 13.9042 5.97596 13.3013 5.95417C12.0013 5.82083 10.8013 6.7125 10.1096 6.7125C9.41793 6.7125 8.44294 5.97083 7.3596 5.9875C6.65138 6.01083 5.96129 6.21732 5.35664 6.58682C4.752 6.95633 4.25344 7.47623 3.9096 8.09583C2.44294 10.6458 3.5346 14.4375 5.00127 16.4958C5.66794 17.5042 6.50127 18.6458 7.60127 18.6042C8.70127 18.5625 9.0596 17.9208 10.3346 17.9208C11.6096 17.9208 12.0013 18.6042 13.0846 18.5792C14.1679 18.5542 14.9346 17.5458 15.6346 16.5375C16.1305 15.8054 16.5178 15.0055 16.7846 14.1625C16.1242 13.8808 15.5607 13.4119 15.1638 12.8136C14.7669 12.2153 14.554 11.5138 14.5513 10.7958Z"
        fill="black"
      />
    </svg>
  )
}

export default Apple
