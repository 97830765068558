import appConfig from '../config/appConfig';
var APIConfig = /** @class */ (function () {
    /**
     * Initialize the configuration file.
     *
     * This must run before any API calls are made.
     */
    function APIConfig() {
        this.baseUrl = '';
        this.defaultLimit = 99999;
        this.timeout = 5 * 60000; // 5 minutes
        this.token = '';
        this.extraHeaders = {};
        this.baseUrl = appConfig.apiBaseUrl;
        this.defaultLimit = appConfig.apiDefaultLimit;
        this.timeout = appConfig.apiTimeout;
        this.extraHeaders = {};
    }
    return APIConfig;
}());
export { APIConfig };
