var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HTTPError, TimeoutError } from 'ky';
import { omit } from 'lodash';
function buildSortString(sortColumn, sortDirection) {
    return "".concat(sortDirection === 'desc' ? '-' : '').concat(sortColumn);
}
var APIBase = /** @class */ (function () {
    function APIBase(config, ky) {
        this.config = config;
        this._ky = ky;
    }
    APIBase.prototype.getActualOptions = function (options) {
        if (options === void 0) { options = {}; }
        var actualOptions = omit(options, ['public']);
        actualOptions.headers = __assign(__assign({}, actualOptions.headers), this.config.extraHeaders);
        if (!options.public && this.config.token) {
            actualOptions.headers = __assign(__assign({}, actualOptions.headers), { Authorization: "Bearer ".concat(this.config.token) });
        }
        return actualOptions;
    };
    // Base methods
    APIBase.prototype.destroyEmpty = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._ky.delete(url, __assign({ prefixUrl: this.config.baseUrl, timeout: this.config.timeout }, this.getActualOptions(options)))];
            });
        });
    };
    APIBase.prototype.getEmpty = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._ky.get(url, __assign({ prefixUrl: this.config.baseUrl, timeout: this.config.timeout }, this.getActualOptions(options)))];
            });
        });
    };
    APIBase.prototype.head = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._ky.head(url, __assign({ prefixUrl: this.config.baseUrl, timeout: this.config.timeout }, this.getActualOptions(options)))];
            });
        });
    };
    APIBase.prototype.patchEmpty = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._ky.patch(url, __assign({ prefixUrl: this.config.baseUrl, timeout: this.config.timeout }, this.getActualOptions(options)))];
            });
        });
    };
    APIBase.prototype.postEmpty = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._ky.post(url, __assign({ prefixUrl: this.config.baseUrl, timeout: this.config.timeout }, this.getActualOptions(options)))];
            });
        });
    };
    APIBase.prototype.putEmpty = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._ky.put(url, __assign({ prefixUrl: this.config.baseUrl, timeout: this.config.timeout }, this.getActualOptions(options)))];
            });
        });
    };
    APIBase.prototype._destroy = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.destroyEmpty(url, options)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.json()];
                }
            });
        });
    };
    APIBase.prototype._get = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getEmpty(url, options)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.json()];
                }
            });
        });
    };
    APIBase.prototype._patch = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.patchEmpty(url, options)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.json()];
                }
            });
        });
    };
    APIBase.prototype._post = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postEmpty(url, options)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.json()];
                }
            });
        });
    };
    APIBase.prototype._put = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putEmpty(url, options)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.json()];
                }
            });
        });
    };
    // Convenience methods
    APIBase.prototype._list = function (url, page, limit, searchParams, options) {
        if (page === void 0) { page = 1; }
        return __awaiter(this, void 0, void 0, function () {
            var actualLimit, offset, params, sortColumn, sortDirection, order;
            return __generator(this, function (_a) {
                actualLimit = limit === -1 ? Number.MAX_SAFE_INTEGER : limit !== null && limit !== void 0 ? limit : this.config.defaultLimit;
                offset = (page - 1) * actualLimit;
                params = __assign({ limit: actualLimit, offset: offset }, searchParams);
                sortColumn = searchParams === null || searchParams === void 0 ? void 0 : searchParams.sortColumn;
                sortDirection = searchParams === null || searchParams === void 0 ? void 0 : searchParams.sortDirection;
                order = sortColumn && sortDirection ? buildSortString(sortColumn, sortDirection) : undefined;
                try {
                    delete params.sortColumn;
                    delete params.sortDirection;
                }
                catch (e) {
                    // pass
                }
                if (order) {
                    params = __assign(__assign({}, params), { order: order });
                }
                return [2 /*return*/, this._get(url, __assign(__assign({}, options), { 
                        // Better than `any`...
                        searchParams: params }))];
            });
        });
    };
    return APIBase;
}());
export { APIBase };
export function handleError(error) {
    return __awaiter(this, void 0, void 0, function () {
        var errorResponse, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(error instanceof HTTPError)) return [3 /*break*/, 2];
                    return [4 /*yield*/, error.response.json()];
                case 1:
                    data = _a.sent();
                    errorResponse = {
                        code: 'generic/400',
                        description: error.message,
                        detail: data,
                    };
                    return [3 /*break*/, 3];
                case 2:
                    if (error instanceof TimeoutError) {
                        errorResponse = {
                            code: 'generic/timeout',
                            description: error.message,
                        };
                    }
                    else if (error instanceof TypeError) {
                        errorResponse = {
                            code: 'generic/network',
                            description: error.message,
                        };
                    }
                    else {
                        errorResponse = {
                            code: 'generic/syntax',
                            description: error.message,
                        };
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/, errorResponse];
            }
        });
    });
}
