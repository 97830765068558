import React from 'react'

const Google: React.FC = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1648 5.67344C11.5732 5.67344 12.5232 6.28177 13.0648 6.7901L15.1815 4.72344C13.8815 3.5151 12.1898 2.77344 10.1648 2.77344C7.23151 2.77344 4.69818 4.45677 3.46484 6.90677L5.88984 8.7901C6.49818 6.98177 8.18151 5.67344 10.1648 5.67344Z"
        fill="#EA4335"
      />
      <path
        d="M17.3641 10.4474C17.3641 9.83073 17.3141 9.38073 17.2057 8.91406H10.1641V11.6974H14.2974C14.2141 12.3891 13.7641 13.4307 12.7641 14.1307L15.1307 15.9641C16.5474 14.6557 17.3641 12.7307 17.3641 10.4474Z"
        fill="#4285F4"
      />
      <path
        d="M5.9013 11.7641C5.74297 11.2974 5.6513 10.7974 5.6513 10.2807C5.6513 9.76406 5.74297 9.26406 5.89297 8.79739L3.46797 6.91406C2.95964 7.93073 2.66797 9.07239 2.66797 10.2807C2.66797 11.4891 2.95964 12.6307 3.46797 13.6474L5.9013 11.7641Z"
        fill="#FBBC05"
      />
      <path
        d="M10.1721 17.7667C12.1971 17.7667 13.8971 17.1 15.1388 15.95L12.7721 14.1167C12.1388 14.5583 11.2888 14.8667 10.1721 14.8667C8.1888 14.8667 6.50547 13.5583 5.90547 11.75L3.48047 13.6333C4.7138 16.0833 7.2388 17.7667 10.1721 17.7667Z"
        fill="#34A853"
      />
    </svg>
  )
}

export default Google
