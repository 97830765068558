import API from '@betablocks/shared/lib/api'
import { useRef } from 'react'
import { getTokenCookie } from '../utils/cookie'

export default function useApi(): API {
  const token = useRef(getTokenCookie())
  const api = useRef(new API({ token: token.current }))

  return api.current
}
