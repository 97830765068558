import React from 'react'

const Fanvestor: React.FC = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.17773 5.82841C2.17773 3.8068 3.81657 2.16797 5.83817 2.16797H15.184C17.2056 2.16797 18.8444 3.8068 18.8444 5.82841V15.1742C18.8444 17.1958 17.2056 18.8346 15.184 18.8346H5.83817C3.81657 18.8346 2.17773 17.1958 2.17773 15.1742V5.82841Z"
        fill="#1A1A1A"
      />
      <mask
        id="mask0_9383_6162"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="15"
        height="11"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.7935 5.35938H10.1727V12.4903L9.10221 11.229V8.75504H7.05859L6.1341 7.68783H9.10221V6.42658H4.86901L5.7935 5.35938ZM3.84375 7.68359H5.25482L10.5111 13.9567L15.7674 7.68359H17.1785L10.5124 15.6391L10.5108 15.6347L10.5098 15.6391L3.84375 7.68359ZM10.9019 5.35938H15.2811L16.2056 6.42658H11.9724V7.68783H14.9405L14.016 8.75504H11.9724V11.229L10.9019 12.4903V5.35938Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_9383_6162)">
        <path
          d="M3.11914 3.125L12.0215 3.14528V16.6406L3.11914 16.6203V3.125Z"
          fill="url(#paint0_linear_9383_6162)"
        />
        <path
          d="M15.7697 7.68359H17.1807L11.9453 13.9318C11.575 14.3736 10.8991 14.3847 10.5146 13.9551L15.7697 7.68359Z"
          fill="#C62BDF"
        />
        <path
          d="M15.2874 5.35938H10.9083V12.4903L11.9787 11.229V8.75504H14.0223L14.9468 7.68783H11.9787V6.42658H16.2119L15.2874 5.35938Z"
          fill="#C62BDF"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9383_6162"
          x1="3.2737"
          y1="7.90189"
          x2="12.6451"
          y2="7.90189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#986E33" />
          <stop offset="1" stopColor="#E6CD71" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Fanvestor
