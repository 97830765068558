import React from 'react'

type Props = React.PropsWithChildren<Record<string, unknown>>

const DividerWithText: React.FC<Props> = ({ children }) => (
  <div className="relative flex my-8 text-gray-400 items-center select-none">
    <div className="flex-grow border-t border-gray-300" />
    <div className="px-2">{children}</div>
    <div className="flex-grow border-t border-gray-300" />
  </div>
)

export default DividerWithText
